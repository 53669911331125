<template>
  <div>
    <ProgressBar :value="progressUploadImages" :showValue="false" v-if="load" />
    <BlockUI :blocked="load">
      <FileUpload
        ref="form"
        name="images[]"
        :customUpload="true"
        @uploader="uploader"
        :multiple="true"
        accept="image/*"
        :maxFileSize="1000000"
        :chooseLabel="'Buscar'"
        :uploadLabel="'Subir'"
        :cancelLabel="'Borrar'"
      >
        <template #empty>
          <p>Arrastre sus archivos aquí para subirlos.</p>
        </template>
      </FileUpload>
    </BlockUI>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      load: false,
    };
  },
  computed: {
    ...mapGetters(["progressUploadImages"]),
  },
  watch: {
    progressUploadImages: function(nVal) {
      if (Math.ceil(nVal) >= 100) {
        this.load = false;
        this.$refs.form.clear();
      }
    },
  },
  methods: {
    ...mapActions(["storeImage", "startCountImages"]),
    async uploader(event) {
      this.load = true;
      this.startCountImages({ count: event.files.length });
      for (var i = 0; i < event.files.length; i++) {
        var file = event.files[i];

        let blob = await fetch(file.objectURL).then((r) => r.blob());
        var name = file.name.replace(/ +/g, "");

        this.storeImage({ name, blob, file, app: this });
      }
    },
  },
};
</script>
