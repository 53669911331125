<template>
  <div class="p-d-flex p-jc-center">
    <Galleria
      :value="images"
      v-model:activeIndex="activeIndex"
      :numVisible="5"
      containerStyle="max-width: 100%"
    >
      <template #item="{item}">
        <img
          @contextmenu="onImageRightClick"
          :src="item.itemImageSrc"
          :alt="item.alt"
          style="width: 100%; display: block;"
        />
      </template>
      <template #thumbnail="{item}">
        <div class="p-grid p-nogutter p-justify-center">
          <img
            :src="item.thumbnailImageSrc"
            :alt="item.alt"
            style="display: block; height: 75px;"
          />
        </div>
      </template>
      <template #caption="{item}">
        <h4 style="margin-bottom: .5rem;">{{ item.title }}</h4>
        <p>{{ item.alt }}</p>
      </template>
    </Galleria>
    <ContextMenu ref="menu" :model="optionsMenu" />
    <Toast />
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      activeIndex: 0,
      items: [],
      optionsMenu: [
        {
          label: "Archivo",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "Eliminar",
              icon: "pi pi-fw pi-trash",
              command: () => this.menuClicked("delete"),
            },
            {
              separator: true,
            },
            {
              label: "Descargar",
              icon: "pi pi-fw pi-external-link",
              command: () => this.menuClicked("download"),
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(["images"]),
  },
  created() {
    this.getImagesFromStore({ app: this });
  },
  methods: {
    ...mapActions(["getImagesFromStore", "deleteImage"]),
    onImageRightClick(event) {
      this.$refs.menu.show(event);
    },
    confirmDelete() {
      this.$confirm.require({
        message: `¿Desea eliminar la imagen [${this.activeIndex + 1}]: ${
          this.images[this.activeIndex].title
        }?`,
        header: "Confirmación de eliminación",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Eliminar",
        accept: () => {
          this.deleteImage({ index: this.activeIndex, app: this });
          this.activeIndex =
            this.activeIndex - 1 >= 0 ? this.activeIndex - 1 : 0;
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "Eliminación cancelada",
            detail: "Imagen aún disponible",
            life: 3000,
          });
        },
      });
    },
    menuClicked(option) {
      switch (option) {
        case "delete":
          this.confirmDelete();
          break;
        case "download":
          this.$toast.add({
            severity: "warn",
            summary: "Función no disponible",
            detail: "Aún no se programa esta funcionalidad",
            life: 3000,
          });
          break;
      }
    },
  },
};
</script>
