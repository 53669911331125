<template>
  <div class="p-grid">
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="Nueva"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"
          @click="openNew"
        />
      </template>
    </Toolbar>
    <div class="p-col-12">
      <GalleriaImages />
    </div>
    <Toast />
    <Dialog
      header="Carga de imagenes"
      :modal="true"
      v-model:visible="display"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
    >
      <FormNewImage />
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          @click="() => (this.display = false)"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import GalleriaImages from "../../components/admin/GalleriaImages.vue";
import FormNewImage from "../../components/admin/FormNewImage.vue";
export default {
  data() {
    return {
      display: false,
      jobImages: [],
    };
  },
  components: {
    GalleriaImages,
    FormNewImage,
  },
  methods: {
    openNew() {
      this.display = true;
    },
    saveImage() {
      this.display = false;
      this.$toast.add({
        severity: "warn",
        summary: "Función no disponible",
        detail: "Aún no se programa esta funcionalidad",
        life: 3000,
      });
    },
  },
};
</script>
